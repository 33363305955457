import MultipleLayerSelect from '@/common/components/MultipleLayerSelect';
import { OptionValue } from '@/common/types';
import { useTranslation } from 'react-i18next';
import { useHierarchicalAssets } from '../hooks/useHierarchicalAssets';
import { filterAssetsForSelect } from '../utils';

type Props = {
  value?: number;
  filterAssetId?: number;
  onChange: (values?: number) => void;
};

export const AssetMultiLayerSelect = ({ value, onChange, filterAssetId }: Props) => {
  const { selectAssetItems } = useHierarchicalAssets();

  const onAssetFilterChange = (option?: OptionValue) => {
    const id = option ? Number(option) : undefined;
    onChange(id);
  };
  const { t } = useTranslation();

  const options = filterAssetId
    ? filterAssetsForSelect(filterAssetId, selectAssetItems)
    : selectAssetItems;

  return (
    <MultipleLayerSelect
      isClearable
      value={value}
      options={options}
      onChange={onAssetFilterChange}
      label={t('pages.asset')}
    />
  );
};
